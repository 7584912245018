import React, { useContext, useState, useEffect, createContext } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { providerOptions } from "../providerOptions";
import { MINT_ABI, MINT_ADDRESS } from "../config";
import { toHex } from "../utills";

const Web3Context = createContext();

const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions
});

export function useAppWeb3Provider() {
    return useContext(Web3Context);
}

export function AppWeb3Provider({ children }) {
    const allowedChain = process.env.React_App_chainID;
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [account, setAccount] = useState();
    const [error, setError] = useState();
    const [chainId, setChainId] = useState();
    const [contractMint, setContractMint] = useState();
    const [methodsMint, setMethodsMint] = useState();

    const connectWallet = async () => {
        try {
            const provider = await web3Modal.connect();
            const library = new Web3(provider);
            const accounts = await new library.eth.getAccounts();
            const network = await new library.eth.getChainId();
            const contractClaim = new library.eth.Contract(MINT_ABI, MINT_ADDRESS);
            setContractMint(contractClaim);
            setMethodsMint(contractClaim.methods);
            setProvider(provider);
            setLibrary(library);
            setChainId(network);
            if (accounts) {
                setAccount(accounts[0]);
            };
        } catch (error) {
            setError(error);
        }
    };

    const switchNetwork = async () => {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: toHex(allowedChain) }]
            });
        } catch (switchError) {
            console.log("PROBLEM CHANGING NETWORK");
        }
    };

    const refreshState = () => {
        setAccount();
        setChainId();
    };

    const disconnect = async () => {
        web3Modal.clearCachedProvider();
        refreshState();
    };

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connectWallet();
        }
    }, []);


    useEffect(() => {
        if (provider?.on) {
            const handleAccountsChanged = (accounts) => {
                console.log("accountsChanged", accounts);
                if (accounts) setAccount(accounts[0]);
            };

            const handleChainChanged = (_hexChainId) => {
                console.log("chainChanged", _hexChainId);
                setChainId(_hexChainId);
            };

            const handleDisconnect = () => {
                console.log("disconnect", error);
                disconnect();
            };

            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("chainChanged", handleChainChanged);
            provider.on("disconnect", handleDisconnect);

            return () => {
                if (provider.removeListener) {
                    provider.removeListener("accountsChanged", handleAccountsChanged);
                    provider.removeListener("chainChanged", handleChainChanged);
                    provider.removeListener("disconnect", handleDisconnect);
                }
            };
        }
    }, [provider]);

    return (
        <Web3Context.Provider value={
            {
                allowedChain: allowedChain,
                provider: provider,
                library: library,
                web3: library,
                account: account,
                error: error,
                chainId: chainId,
                contractMint: contractMint,
                methodsMint: methodsMint,
                connectWallet: connectWallet,
                switchNetwork: switchNetwork,
            }
        }>
            {children}
        </Web3Context.Provider>
    )
}