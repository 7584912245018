import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
require('dotenv').config();

export const providerOptions = {
    walletlink: {
        package: CoinbaseWalletSDK, // Required
        options: {
            appName: "Connect Your Wallet",
            infuraId: process.env.React_App_INFURA_KEY
        }
    },
    walletconnect: {
        package: WalletConnect,
        options: {
            cacheProvider: false,
            infuraId: process.env.React_App_INFURA_KEY,
            qrcodeModalOptions: {
                mobileLinks: [
                    "rainbow",
                    "trust",
                    "argent",
                    "metamask",
                    "pillar",
                    "imtoken",
                    "bitpay",
                    "crypto",
                    "mathwallet",
                    "tokenpocket",
                    "onto",
                ]
            }
        }
    }
};
