import React from 'react';
import ReactDOM from "react-dom";
import App from './App';
import { AppWeb3Provider } from './contexts/Web3Context';

const rootElement = document.getElementById("root");
ReactDOM.render(
    <AppWeb3Provider>
        <App />
    </AppWeb3Provider>,
    rootElement
);
